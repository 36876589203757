import React, { useEffect } from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../../../components/Layouts/mainPage"
import { NewHeader } from "../../../components/Headers/NewHeader"
import { FourAdvantagesPoints } from "../../../components/FourAdvantagesPoints"
import { Partners } from "../../../components/PartnersNew"
import { HowInstallmentWorks } from "../../../components/HowInstallmentWorks"
import { getPageData } from "../../../helpers/getPageData"

import { PageData } from "../../../interfaces/pageProps"

import { Banner } from "../../../components/Banners/BannerNewYearMedia"
import { FormPKW } from "../../../components/FormPKW"
import { autoPromo } from "../../../components/PartnersNew/helpers"
import { BottomCta } from "../../../components/BottomCta"
import NewYearMediaImg from "../../../components/BottomCta/BgImg/NewYearMedia"
import { NewFooter } from "../../../components/NewFooter"

export default function Page({ data }: PageProps<PageData>) {
  const { noIndex, ligal } = getPageData(data)
  const countDownTime = 1800000
  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  const seoData = {
    title: "Бесплатная доставка карты Халва",
  }

  const bannerTitle = <>До 36 месяцев рассрочки с&nbsp;картой «Халва»</>
  const bannerDescr = "до 5000 рублей кэшбэка"
  const formTitle = (
    <>
      Оформите карту сейчас <br /> и примите участие в розыгрыше
    </>
  )

  const timerDescr = (
    <>
      Играйте в игру с <span style={{ color: "#EC5C57", fontWeight: "500" }}>09.12 до 12.01</span>,
      получайте подарки от партнеров акции
    </>
  )

  const subTitlePartners = <>Совершайте покупки и участвуйте в&nbsp;розыгрыше ценных призов</>

  return (
    <Layout seoData={seoData} noHeader noFooter noIndex={noIndex}>
      <NewHeader redLogoWithSovcombank showHint countDownTime={countDownTime} />
      <Banner description={bannerDescr} title={bannerTitle} orderNum="1" />
      <FourAdvantagesPoints
        title="Преимущества с Халвой:"
        indent={false}
        withTitle
        variant="newYearMedia"
        orderNum="2"
      />
      <HowInstallmentWorks
        orderNum="3"
        variant="newYearMedia"
        headTitle={<>Как получить рассрочку до 36 месяцев</>}
      />
      <FormPKW
        progressBar
        isGreenApprove
        title={formTitle}
        dataLayerName="shortPersonalForm"
        formBtnText="Оформить карту"
        orderNum="4"
        emptyForm
        backgroundColor="#EEF5FB"
        variant="newYearMedia"
        countDownTime={countDownTime}
        withTimer
        longTitle
        timerDescription={timerDescr}
        anotherTimerDescr={timerDescr}
      />
      <Partners
        title="Более 260&nbsp;000 магазинов-партнеров"
        subTitle={subTitlePartners}
        orderNum="5"
        variant="newYearMedia"
        additionalPartners={autoPromo}
      />
      <BottomCta
        title="Не упустите свой шанс!"
        orderNum="6"
        BgImg={NewYearMediaImg}
        variant="newYearMedia"
        newYear
      />
      <NewFooter ligal={ligal} orderNum="7" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      page(url: "https://halvacard.ru/order/pkw/novyi-god-halva/media/") {
        url
        notIndex
        ligal {
          text
        }
      }
    }
  }
`
